<template>
<div>
  <h1 v-if="heading === 'h1'" style="margin-bottom: 20px; margin-left:0px; margin-right: 0px; font-size: 24px; color: #00338D">{{item.name}}</h1>
  <h3 v-if="heading === 'h3'" style="margin-bottom: 20px; margin-left:0px; margin-right: 0px; color: #00338D">{{item.name}}</h3>
  <div v-if="options.country.value"><p>{{item.country}} | {{item.impactstatus.name}}</p></div>
  <div v-if="item.impactpolicy_id > 0 && options.policyDetails.value && !item.impactpolicy.overview">
      <div><h4 class="mt-5">Policy details</h4></div>
      <policy-initiative-details :item="item" />
  </div>
  <p v-if="options.headline.value">{{item.headline}}</p>
  <div v-if="options.descriptionInitiative.value" v-html="item.description"></div>
  <div v-if="item.impactexperts.length > 0 && options.experts.value">
    <h4 class="mt-5">Specialists</h4>
    <div v-for="expert in item.impactexperts" :key="'expert'+expert.id">
      {{expert.name}}
    </div>
  </div>
  <div v-if="item.impactinitiativeslinks.length > 0 && options.externalLinks.value">
    <h4 class="mt-5">External links</h4>
    <div v-for="link in item.impactinitiativeslinks" :key="'link'+link.id" class="mb-3">
      <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
    </div>
  </div>
  <div class="page-break" style="page-break-after: always;"></div>
</div>
</template>

<script>
import PolicyInitiativeDetails from '@/components/PolicyInitiativeDetailsSimple'
import moment from 'moment'
import workflow from '@/workflows/initiative'

export default {
  name: 'InitiativeReport',
  props: {
    item: {
      type: Object,
      required: true
    },
    heading: {
      type: String,
      required: false,
      default: 'h1'
    },
    options: {
      type: Object,
      required: true
    }
  },
  components: {
    PolicyInitiativeDetails
  },
  data () {
    return {
      moment: moment,
      workflow: workflow
    }
  }
}
</script>

<style>
</style>
